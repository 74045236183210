export const CookieManager = {
    getCookie(cookieName) {
        const name = cookieName + "=";
        const cookies = document.cookie
        const cs = cookies.split(';');
        for (let i = 0; i < cs.length; i++) {
            let c = cs[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    setCookie(name,value,days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    deleteCookie(cookieName) {
        this.setCookie(cookieName, "", 0);
    }
};
