import {TPermission} from '@/views/mixins/PermissionMixin'
export interface ISearchType {
	name: string
	permission_string: TPermission | null
	submit_string: string

	description: string
	icon_string: string
}

export const ESearchTypes: Array<ISearchType> = [
	{
		name: 'Freedom to Operate',
		submit_string: 'FTO',
		permission_string: 'FTO',
		description: 'Identify IP barriers obstructing production or distribution of your technologies',
		icon_string: 'fto.png',
	},
	{
		name: 'Patentability',
		submit_string: 'Patentability',
		permission_string: 'PATENTABILITY',
		description: 'Determine if your invention is novel and eligible for protection',
		icon_string: 'patentability.png',
	},
	{
		name: 'Invalidation',
		submit_string: 'Invalidation',
		permission_string: 'INVALIDATION',
		description: 'Find opposing prior art to invalidate an existing patent',
		icon_string: 'invalidation.png',
	},
	{
		name: 'State of the Art',
		submit_string: 'State of the Art',
		permission_string: 'STATE_OF_THE_ART',
		description: 'Review existing technologies to assess innovation and trends',
		icon_string: 'sota.png',
	},

	{
		name: 'Fielded Search',
		submit_string: 'Fielded Search',
		permission_string: 'SINGLE_DOCUMENT_SEARCH',
		description: 'Classical field-based patent search using strict filters',
		icon_string: 'fielded.png',
	},
	{
		name: 'Ultra Quick',
		submit_string: 'Ultra Quick',
		permission_string: 'ULTRA_QUICK',
		description: 'Non-transparant autonomous AI patent search',
		icon_string: 'quick.png',
	},
	{
		name: 'predoriX',
		submit_string: 'Predori X',
		permission_string: 'PREDORI_X',
		description: 'Instant patent information at your fingertips in conversational format',
		icon_string: 'x.png',
	},
]
