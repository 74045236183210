import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import RightNavbar from '@/components/RightNavbar.vue'


const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		alias: '/search/select_search_type/:projectId?',
		name: 'home',
		components: {
			default: DashboardView,
		},
		meta: {title: 'Quick Start'},
	},
	{
		path: '/search/search_input/:searchId',
		name: 'search_input',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/SearchInputView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Describe your idea'},
	},
	{
		path: '/search/invalidation_input/:searchId',
		name: 'invalidation_input',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/InvalidationInputView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Invalidation Process'},
	},
	{
		path: '/search/phrase_selection/:searchId',
		name: 'phrase_selection',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/PhraseSelectionView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Select Technical Terms or Phrases'},
	},
	{
		path: '/search/synonym_selection/:searchId',
		name: 'synonym_selection',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/SynonymSelectionView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {
			reload: true,
			title: 'Select Appropriate Synonyms',
		},
	},
	{
		path: '/search/technology_selection/:searchId',
		name: 'technology_selection',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/TechnologySelectionView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {
			title: 'Please rate the relevancy of the suggested technology field. Rate the whole field as relevant, if there is at least one aspect matching with your idea.',
		},
	},
	{
		path: '/search/plausibility_check/:searchId',
		name: 'plausibility_check',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/PlausibilityCheckView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Select Plausibility Check'},
	},
	{
		path: '/search/fto_filters/:searchId',
		name: 'fto_filters',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/FtoFiltersView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Freedom to Operate'},
	},
	{
		path: '/search/doc_monitoring_input/:searchId',
		name: 'doc_monitoring_input',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/DocMonitoringInputView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Describe your monitoring idea'},
	},
	{
		path: '/search/fto_legal_status_filter/:searchId',
		name: 'fto_legal_status_filter',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/FtoLegalStatusFilterView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'FTO Status Filter'},
	},
	{
		path: '/search/doc_monitoring_field_settings/:searchId',
		name: 'doc_monitoring_field_settings',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/DocMonitoringFieldSettingsView.vue'
				),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Select the field settings for your monitoring job'},
	},
	{
		path: '/search/technology_monitoring_job_settings/:searchId',

		name: 'technology_monitoring_job_settings',

		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/TechnologyMonitoringJobSettingsView.vue'
				),

			'right-menu-router': RightNavbar,
		},

		meta: {title: 'Select the settings for your monitoring job'},
	},
	{
		path: '/search/document_monitoring_job_settings/:searchId',
		name: 'document_monitoring_job_settings',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/DocumentMonitoringJobSettingsView.vue'
				),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Select the settings for your monitoring job'},
	},
	{
		path: '/search/tech_monitoring_search_input/:searchId',
		name: 'tech_monitoring_search_input',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/TechMonitoringInputView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Describe your monitoring idea'},
	},
	{
		path: '/search/sdoc_input/:searchId',
		name: 'sdoc_input',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/SDOCInputView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Single-Document-Search'},
	},
	{
		path: '/search/result_list/:searchId',
		name: 'result_list',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/SearchResultListView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Result List'},
	},
	{
		path: '/search/screen_results/:searchId',
		name: 'screen_results',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/SearchScreenResultsView.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Screen Results'},
	},
	{
		path: '/search/final_results/:searchId',
		name: 'final_results',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/SearchFinalResults.vue'),
			'right-menu-router': RightNavbar,
		},
		meta: {title: 'Final Results'},
	},
	{
		path: '/search/results_collection/:searchId',
		name: 'results_collection',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/SearchFinalResults.vue'),
			// 'right-menu-router': RightNavbar
		},
		meta: {title: 'Results Collection'},
	},
	{
		path: '/search/result_detail/:searchId/:ucid',
		name: 'result_detail',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/AssesmentResultDetailView.vue'),
			// 'right-menu-router': RightNavbar
		},
		meta: {title: 'Detailview'},
	},

	{
		path: '/search/reporting_center/:searchId',
		name: 'reporting_center',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/ReportingCenterView.vue'),
		},
		meta: {title: 'Report Documents'},
	},
	{
		path: '/search/project_list/:projectId',
		name: 'project_list_id',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/ProjectListView.vue'),
		},
		meta: {title: 'Project List'},
	},
	{
		path: '/search/project_list',
		name: 'project_list',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/ProjectListView.vue'),
		},
		meta: {title: 'Project List'},
	},
	{
		path: '/search/folder_list/:folderId',
		name: 'folder_list_id',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/FolderListView.vue'),
		},
		meta: {title: 'Folder List'},
	},
	{
		path: '/search/folder_list',
		name: 'folder_list',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/FolderListView.vue'),
		},
		meta: {title: 'Folder List'},
	},
	{
		path: '/company/users',
		name: 'company_users',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/Company/CompanyUsersView.vue'),
		},
		meta: {title: 'Company Users List'},
	},
	{
		path: '/company/onboarding',
		name: 'company_onboarding',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/Company/CompanyOnboardingView.vue'
				),
		},
		meta: {title: 'Company Onboarding'},
	},
	{
		path: '/company/:companyId',
		name: 'company_representation_id',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/Company/CompanyRepresentationView.vue'
				),
		},
		meta: {title: 'Company Representation'},
	},
	{
		path: '/company',
		name: 'company_representation',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "about" */ '../views/Company/CompanyRepresentationView.vue'
				),
		},
		meta: {title: 'Company Representation'},
	},
	{
		path: '/company/:companyId/category/:categoryId',
		name: 'company_id_category_id',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/Company/CategoryDetailView.vue'),
		},
		meta: {title: 'Company Category'},
	},
	{
		path: '/company/:companyId/competitor/:competitorId',
		name: 'company_id_competitor_id',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/Company/CompetitorDetailView.vue'),
		},
		meta: {title: 'Company Competitor'},
	},
	{
		path: '/augmentations/tag_list',
		name: 'tag_list',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/AugmentationsTagList.vue'),
		},
		meta: {title: 'Tags'},
	},
	//----------------------------------------------------------------
	// #region PredoriX
	{
		path: '/documents/:ucid',
		name: 'document_viewer',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/DocumentView.vue'),
		},
		meta: {title: 'Documents'},
	},
	{
		path: '/x/:x_id',
		name: 'predori_x',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../PredoriX/views/PredoriXSearchView.vue'),
		},
		meta: {title: 'Predori X'},
	},

	// #endregion
	//----------------------------------------------------------------
	// #region Monitoring

	{
		path: '/monitoring',
		name: 'monitoring_list',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/MonitoringListView.vue'),
		},
		meta: {title: 'Monitorings'},
	},
	{
		path: '/monitoring/:monitoringProjectId',
		name: 'monitoring_list_id',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/MonitoringListView.vue'),
		},
		meta: {title: 'Monitorings'},
	},
	{
		path: '/monitoring/:monitoringProjectId/technology',
		name: 'monitoring_create_tech',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/MonitoringCreateTech.vue'),
		},
		meta: {title: 'Technology Monitoring'},
	},
	{
		path: '/monitoring/:monitoringProjectId/document',
		name: 'monitoring_create_doc',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/MonitoringCreateDoc.vue'),
		},
		meta: {title: 'Document Monitoring'},
	},
	{
		path: '/monitoring/edit/:monitoringJobId',
		name: 'monitoring_edit',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/MonitoringEditView.vue'),
		},
		meta: {title: 'Edit Monitoring'},
	},

	// #endregion
	//----------------------------------------------------------------
	// #region DEV

	{
		path: '/dev/sandbox',
		name: 'dev_sandbox',
		components: {
			default: () => import(/* webpackChunkName: "about" */ '../views/dev/_Sandbox.vue'),
		},
		meta: {title: 'Sandbox'},
	},
	{
		path: '/dev/apicall',
		name: 'dev_apiTest',
		components: {
			default: () =>
				import(/* webpackChunkName: "about" */ '../views/dev/DevApiTestView.vue'),
		},
		meta: {title: 'APICall'},
	},

	// #endregion
	//----------------------------------------------------------------
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
})

export default router
