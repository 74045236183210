export const PermissionMixin = {
	methods: {
		userHasPermission(permission: TPermission) {
			const user_permissions = localStorage.getItem('user_permissions') || '{}'
			const permissions = JSON.parse(user_permissions)
			return permissions[permission]
		},
	},
}

export type TPermission =
	| 'DOCUMENT_MONITORING'
	| 'FTO'
	| 'INVALIDATION'
	| 'PATENTABILITY'
	| 'SINGLE_DOCUMENT_SEARCH'
	| 'STATE_OF_THE_ART'
	| 'TECHNOLOGY_MONITORING'
	| 'ULTRA_QUICK'
	| 'BETA_TESTER'
	| 'PREDORIAN'
	| 'TAGS'
	| 'PREDORI_X'
	| 'TEST_FALSE'