import { createApp } from 'vue'
import VueClipboard from 'vue3-clipboard'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueEventer from 'vue-eventer';
import {RouteLocationNormalized} from "vue-router";

// Set up a global error handler
window.addEventListener('error', async (event) => {
	const userStore = useUserStore();
	const user = await userStore.getUser();
	const userId = user ? user.id : 'n/a';

	let additionalMessage = '\n Url: ' + window.location.href + '\n';
	additionalMessage += 'User: ' + userId + '\n';

	PdLogger.errorFromException(event.error, additionalMessage);
});


export const app = createApp(App);
app.use(router)
app.config.globalProperties.$eventBus = new VueEventer();

app.use(VueClipboard, {  autoSetContainer: true,
    appendToBody: true,})

app.use( CKEditor)

app.config.globalProperties.$snackbarStack = [];

// make eventbus available to external components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.document.$spaEventBus = app.config.globalProperties.$eventBus;
app.provide('eventBus', app.config.globalProperties.$eventBus);


// register custom v-click-outside directive
app.directive('click-outside', {
    mounted(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
// register custom v-autofocus directive
app.directive('autofocus', {
    mounted(el) {
        el.focus()
    }
})

// Vue Pinia
import {createPinia} from 'pinia'
const pinia = createPinia()
app.use(pinia)

app.mount('#app')


// bind router before  events
router.beforeEach((to, from, next) => {
    let title = 'Predori - Patent Search Done Right';
    if (to['meta'] && to['meta']['title']) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title = to.meta['title'].toString();
    }
    document.title = title;

    next()
})

function resetEventStroreAndPreserveGlobalEvents() {
    // events we want to preserve during route changes
    const globalEventKeys = ['update-right-navbar-data', 'is_page_dirty', 'set-max-step', 'remember-dirty-before-save'];
    const globalEvents = {};
    // backup events we want to have global
    globalEventKeys.forEach((event: string) => {
        if (app.config.globalProperties.$eventBus.events[event]) {
            globalEvents[event] = app.config.globalProperties.$eventBus.events[event];
        }
    });
    // reset the eventbus
    app.config.globalProperties.$eventBus.events = [];
    // restore global events
    Object.keys(globalEvents).forEach((event: string) => {
        app.config.globalProperties.$eventBus.events[event] = globalEvents[event];
    })
}

import {useProjectListActiveId} from '@/stores/managementProjectStore'
import {useFolderListActiveId} from '@/stores/managementFolderStore'
// Pre-Fetch User Info
import { useUserStore } from './stores/userInfoStore'
const userStore = useUserStore()
userStore.getUser()

import PdLogger from "./lib/PdLogger";
const projectActiveId = useProjectListActiveId()
const folderActiveId = useFolderListActiveId()
// bind router after events
router.afterEach((to, from, next) => {
	// Prevent resetting the eventBus when navigating between projects/folders
	// Set correct current active Folder/Project ID when navigating wiht forward/backward browser buttons
	if (from.name === 'project_list' || from.name === 'project_list_id') {
		if (to.name === 'project_list' || to.name === 'project_list_id') {
			const nextProjectId = to.params.projectId ? to.params.projectId : null
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			projectActiveId.activeId = nextProjectId
			return
		}
	}
	if (from.name === 'folder_list' || from.name === 'folder_list_id') {
		if (to.name === 'folder_list' || to.name === 'folder_list_id') {
			const nextFolderId = to.params.folderId ? to.params.folderId : null
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			folderActiveId.activeId = nextFolderId
			return
		}
	}
    // console.log('router', to, from, next);
    resetEventStroreAndPreserveGlobalEvents();

    // make eventbus available to external components
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.document.$spaEventBus = app.config.globalProperties.$eventBus;

    const checkForRightNavbar = function(to: RouteLocationNormalized) {
        const elem = document.getElementById('site-content');
        if(elem === null){
            return;
        }

        // check if the right navbar is set and set the navbar handling class accordingly
        if (to &&
            to.matched[0] &&
            to.matched[0]['components'] &&
            to.matched[0]['components']['right-menu-router'] &&
            to.matched[0]['components']['right-menu-router'].name == 'RightNavbar'
        ) {
            elem.classList.add('right-menu-is-rendered');
        } else {
            elem.classList.remove('right-menu-is-rendered');
        }
    }
    const updateLeftNavbar = function (to: RouteLocationNormalized) {
		const menuItems = document.querySelectorAll('.main-menu-list-item')
		menuItems.forEach((el) => {
			el.classList.remove('active')
		})
		let t: null|string = null;
		switch (to.name) {
            case 'home':
                t = 'quick-start'
                break
			case 'project_list':
			case 'project_list_id':
				t = 'my-projects'
				break
			case 'folder_list':
			case 'folder_list_id':
				t = 'my-folders'
				break
			case 'monitoring_list':
			case 'monitoring_list_id':
				t = 'my-monitoring'
				break
            case 'tag_list':
                t ='my-tags'
                break
			default:
                t = null;
		}
        if (t !== null) {
            const activeItem = document.getElementById(`main-menu-list-item__${t}`)
            if (activeItem) activeItem.classList.add('active')
        }
	}
	checkForRightNavbar(to)
	updateLeftNavbar(to)
});


const loaderWatcher = function() {
	const loaderElem = document.querySelector('.pd-loader.pd-loader-extendable');
	if (!loaderElem) {
		return
	}

	let visibleSince = 0;
	const longLoaderWaitingTime = 25;

	const loaderObserver = function() {
		const loaderStyle = getComputedStyle(loaderElem);
		if (loaderStyle.display === 'flex') {
			visibleSince++;
		} else if (loaderStyle.display === 'none') {
			visibleSince = 0;
		}

		if (visibleSince > longLoaderWaitingTime) {
			loaderElem.classList.add('pd-loader-extended');
			const loaderIconStart = document.querySelector('.pd-loader-icon-start') as HTMLElement | null;
			const longLastingLoader = document.querySelector('.longLastingLoader') as HTMLElement | null;
			if (!loaderIconStart || !longLastingLoader) {
				return;
			}

			loaderIconStart.style.transition = 'opacity 2s';
			loaderIconStart.style.opacity = '0';
			setTimeout(function() {
				loaderIconStart.style.display = 'none';
				longLastingLoader.style.display = 'block';
				longLastingLoader.style.transition = 'opacity 2s';
				longLastingLoader.style.opacity = '1';
			}, 2000);
		} else {
			loaderElem.classList.remove('pd-loader-extended');
		}
	};

	setInterval(loaderObserver, 1000);
};
loaderWatcher();


// @todo this should be moved as functionality to the imageCarrousel component
function overlayHandler() {
	// renders a overlay with a big image when hovering over a small image in the imageCarroussel
	const easyZoom = {
		contextElement: null as HTMLElement | null,
		options: {
			className: 'imageZoom',
			parent: document.body,
			zoomWindowSize: 500, // size of the zoom overlay window
			maxZoomSize: 750, // size of the background image in zoomWindowSize
			append: true,
			preload: '<div class="pd-inline-loader pd-loader-small"><div class="pd-loader-icon"></div></div>',
			error: 'There has been a problem with loading the image.',
		},
		defaults: {
			className: 'imageZoom',
			parent: document.body,
			zoomWindowSize: 500, // size of the zoom overlay window
			maxZoomSize: 750, // size of the background image in zoomWindowSize
			append: true,
			preload: '<div class="pd-inline-loader pd-loader-small"><div class="pd-loader-icon"></div></div>',
			error: 'There has been a problem with loading the image.',
		},

		init: function (options: Partial<typeof this.options>) {
			this.options = { ...this.defaults, ...options };

			document.body.addEventListener('mouseenter', (e) => {
				const target = e.target as HTMLElement;
				if (target.classList.contains(this.options.className)) {
					this.start(e as MouseEvent);
				}
			}, true); // useCapture to ensure it captures the event before it reaches the target

			document.body.addEventListener('mouseleave', (e) => {
				const target = e.target as HTMLElement;
				if (target.classList.contains(this.options.className)) {
					this.close();
				}
			}, true);

			document.body.addEventListener('mousemove', (e) => {
				const target = e.target as HTMLElement;
				if (target.classList.contains(this.options.className)) {
					this.move(e as MouseEvent);
				}
			}, true);


			// Add scroll event listener to a specific container
			const scrollContainer = document.querySelector('.pd-pagination-scroll-container');
			if (scrollContainer) {
				scrollContainer.addEventListener('scroll', () => {
					if (this.contextElement) {
						this._setOverlayPosition(this.contextElement);
					}
				});
			}

		},

		_setOverlayPosition: function (element: HTMLElement) {
			const pos = this._getPositions(element);
			const zoomWindow = document.getElementById('zoomWindowOverlay') as HTMLElement;
			zoomWindow.style.left = `${pos.x}px`;
			zoomWindow.style.top = `${pos.y}px`;
		},

		_getPositions: function (element: HTMLElement) {
			const boundingRect = element.getBoundingClientRect();
			const xPos = boundingRect.right + 40; // Add some offset to the right
			const yPos = boundingRect.top - 26;
			return { x: xPos, y: yPos, width: boundingRect.width, height: boundingRect.height };
		},

		start: function (e: MouseEvent) {
			if (!e.currentTarget) {
				return;
			}
			this.contextElement = e.target as HTMLElement;

			const pos = this._getPositions(this.contextElement);
			const zoomWindowSize = Math.min(370, this.options.zoomWindowSize);

			const imageUrl = getComputedStyle(this.contextElement).backgroundImage;
			const imageRotation = getComputedStyle(this.contextElement).transform;

			const test = pos.x + zoomWindowSize + 50;
			if (test > window.innerWidth) {
				pos.x = pos.x - pos.width - zoomWindowSize - 50;
			}

			// Create zoom window overlay
			const zoomWindowDiv = document.createElement('div');
			zoomWindowDiv.id = 'zoomWindowOverlay';
			zoomWindowDiv.style.position = 'fixed';
			zoomWindowDiv.style.top = `${pos.y}px`;
			zoomWindowDiv.style.left = `${pos.x}px`;
			zoomWindowDiv.style.zIndex = '9999';
			zoomWindowDiv.style.backgroundColor = '#ffffff';
			zoomWindowDiv.style.height = `${zoomWindowSize}px`;
			zoomWindowDiv.style.width = `${zoomWindowSize}px`;
			zoomWindowDiv.style.backgroundRepeat = 'no-repeat';
			zoomWindowDiv.style.transform = imageRotation;
			zoomWindowDiv.style.border = '1px solid #d8d8d8';
			zoomWindowDiv.innerHTML = this.options.preload;
			this.options.parent.appendChild(zoomWindowDiv);

			const img = new Image();
			img.src = imageUrl.replace(/url\("|"\)$/ig, "");

			img.onload = () => {
				let bgImgWidth = img.width;
				let bgImgHeight = img.height;

				if (bgImgWidth > this.options.maxZoomSize) {
					bgImgHeight *= (this.options.maxZoomSize / bgImgWidth);
					bgImgWidth = this.options.maxZoomSize;
				}

				const zoomWindow = document.getElementById('zoomWindowOverlay');
				if (!zoomWindow) {
					return;
				}
				zoomWindow.innerHTML = ''; // Remove loader
				zoomWindow.style.backgroundImage = imageUrl;
				zoomWindow.style.backgroundSize = `${bgImgWidth}px ${bgImgHeight}px`;
			};
		},

		move: function (e: MouseEvent) {
			if (!this.contextElement) {
				return;
			}
			const contextElement = this.contextElement as HTMLElement;
			const xPercent = Math.round(e.offsetX / contextElement.offsetWidth * 100);
			const yPercent = Math.round(e.offsetY / contextElement.offsetHeight * 100);
			const zoomWindow = document.getElementById('zoomWindowOverlay') as HTMLElement;
			zoomWindow.style.backgroundPosition = `${xPercent}% ${yPercent}%`;
		},

		close: function () {
			this.contextElement = null;
			const zoomWindow = document.getElementById('zoomWindowOverlay');
			if (zoomWindow) {
				zoomWindow.remove();
			}
		}
	};

	easyZoom.init({});
}
overlayHandler();

/**
 * Bind events to the main menue and right menue toggle
 * on click they toggle the menu size
 * on pageload they also resize the menues to theit last pages state
 */
const mainMenuToggle = document.getElementById('main-menu-toggle');
if (mainMenuToggle) {
	const toggleElement = document.getElementById('main-menu-toggle') as HTMLElement;
	toggleElement.addEventListener('click', function () {
		const siteElement = document.getElementById('site-content') as HTMLElement;
		const className = 'main-menu-collapsed'
		const hasClass = siteElement.classList.contains(className);
		if (hasClass) {
			siteElement.classList.remove(className)
		} else {
			siteElement.classList.add(className);
		}
	});
}
