import { fetchPOST } from "@/views/mixins/PredoriFetch";
type LoggerType = 'info' | 'warn' | 'error';

/*
 * Logs data to the console or/and to a remote server.
 * To configure the logger, set the following variables in the .env file's of the stages:
 * VUE_APP_LOG_REMOTE=true
 * VUE_APP_STAGE=dev|staging|live (this is not exclusive to the logger)
 */
class PdLogger {
    private static logRemote(type: LoggerType, headline: string, message: string, stage: string) {
        const url = `api/logging/${type}/${stage}`;
        const formData: FormData = new FormData();
        formData.append('headline', headline);
        formData.append('message', message);
        formData.append('type', type);
        if (stage==='development') {
            console.log('PdLogger: [info] Sending log to remote server skipped as we are on development', {url, formData})
            return
        }
        fetchPOST({url, formData})
    }

    private static logConsole(type: LoggerType, headline, message: string|null ) {
        const loggerMessage = `PdLogger: [${type}] ${headline} \n ${message}`;
        switch (type) {
            case 'info':
                console.log(loggerMessage);
                break;
            case 'warn':
                console.warn(loggerMessage);
                break;
            case 'error':
                console.error(loggerMessage);
                break;
        }
    }

    private static log(type: LoggerType, headline, message: string ) {
        if (process.env.VUE_APP_STAGE !== 'live') {
            this.logConsole(type, headline, message);
        }

        if (process.env.VUE_APP_LOG_REMOTE === 'true') {
            const stage = process.env.VUE_APP_STAGE
            this.logRemote(type, headline, message, stage);
        }
    }

    static info(headline, message) {
        this.log('info', headline, message);
    }

    static warn(headline, message) {
        this.log('warn', headline, message);
    }

    static error(headline, message) {
        this.log('error', headline, message);
    }

    static errorFromException(e: Error, additionalMessage =  '') {
        const headline = e.message
        const errorMessage = `${e.name}\n` +
                            `${e.stack} \n` +
                            `${additionalMessage}`;
        this.error(headline, errorMessage);
    }
}

export default PdLogger
