import {defineStore} from 'pinia'
import {Ref, ref} from 'vue'
import {fetchGET} from '@/views/mixins/PredoriFetch'

export const useUserStore = defineStore('user_info', () => {
	const user: Ref<IUser | null> = ref(null)
	async function getUser() {
		if (user.value !== null) return user.value
		const newUser = await fetchUser()
		user.value = newUser
		return newUser
	}
	return {getUser}

	async function fetchUser() {
		const url = '/spa/api/users/self'
		const json = await fetchGET<IUserResponse>({url: url})
		if (json === undefined) return null

		const user: IUser = {
			id: json.user.pk,
			company_id: json.user.company !== undefined ? json.user.company : null,
			first_name: json.user.first_name ? json.user.first_name : '',
			last_name: json.user.last_name ? json.user.last_name : '',
			email: json.user.email,
			is_active: json.user.is_active,
			is_admin_user: json.user.is_admin_user,
		}
		return user
	}
})

export interface IUser {
	id: number
	company_id: null | number
	first_name: string
	last_name: string
	email: string

	is_active: boolean
	is_admin_user: boolean
}

interface IUserResponse {
	user: {
		pk: number
		company: number | null | undefined
		first_name: string
		last_name: string
		email: string

		is_active: boolean
		is_admin_user: boolean
	}
}
