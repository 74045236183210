import {CookieManager} from "@/views/helpers/CookieManager";

export const LOCAL_STORAGE_BY_SEARCH_FIELD_NAMES = {
    local_augmentation_data: 'local_augmentation_data',
    active_assessment_result_index: 'active_assessment_result_index'
}

export const LOCAL_STORAGE_BY_SEARCH_MANAGER =  {
    set: function(search_id, key, data) {
        let searchData = this.getLocalStorageBySearchFull(search_id);
        if (searchData === null) {
            searchData = {}
        }
        searchData[key] = data;
        localStorage.setItem(this.getSearchKey(search_id), JSON.stringify(searchData));
    },
    delete: function(search_id, key) {
        const searchData = this.getLocalStorageBySearchFull(search_id);
        if (searchData && searchData[key] && typeof searchData[key] !== 'undefined') {
            delete searchData[key];
        }
        localStorage.setItem(this.getSearchKey(search_id), JSON.stringify(searchData));
    },
    get: function(search_id, key) {
        const storageData = this.getLocalStorageBySearchFull(search_id);
        if (storageData === null) {
            return {};
        }
        return storageData[key];
    },
    getLocalStorageBySearchFull: function(search_id) {
        return JSON.parse(localStorage.getItem(this.getSearchKey(search_id)) || '{}');
    },
    getSearchKey: function(search_id) {
        return 'search_data_' + search_id;
    },
    deleteAllAugmentations: function() {
        // delete all loacalStorage keys starting with 'search_data_'
        for (const key in localStorage){
            if (key.indexOf('search_data_') > -1) {
                localStorage.removeItem(key);
            }
        }
    },
    checkFlushAugmentationCookie: function() {
        const flushAugmentations = CookieManager.getCookie('flush_augmentations');
        if (parseInt(flushAugmentations) == 1) {
            this.deleteAllAugmentations();
            CookieManager.deleteCookie('flush_augmentations');
        }
    }
};
// check if the augmentations need to be flushed on every request.
// The cookie triggering rhe flush is set on login as we expect that a user is fine that he looses his dirty augmentations of his last session after login
LOCAL_STORAGE_BY_SEARCH_MANAGER.checkFlushAugmentationCookie();