<template>
	<div>
		<!-- Your app content -->

		<!-- Conditional rendering of the page leave warning overlay -->
		<div v-if="isOverlayVisible" class="pd-pageleave-overlay" id="overlay">
			<div class="overlay-container autoHeight with-max-width noMinHeight">
				<div class="close-button" @click="hideOverlay">
					<i class="icon icon-icon_close icon-size-30"></i>
				</div>
				<div id="overlay-body" class="overlay-body height-is-100">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-12 is-headline-and-sub-container">
								<h1 class="is-text-subline">Leave Page?</h1>
								<span class="is-text-caption">There are unsaved changes. Would you like to save your search before leaving?</span>
							</div>
						</div>
					</div>
					<div class="container-fluid">
						<div class="row">
							<div class="offset-md-1 col-md-5">
								<button class="btn btn-small btn-red ripple" @click="confirmLeave">Yes, Leave!</button>
							</div>
							<div class="col-md-5">
								<button class="btn btn-small ripple" @click="hideOverlay">No, Stay!</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	LOCAL_STORAGE_BY_SEARCH_FIELD_NAMES,
	LOCAL_STORAGE_BY_SEARCH_MANAGER
} from "@/views/helpers/LOCAL_STORAGE_BY_SEARCH_MANAGER";


export default {
	data() {
		return {
			isOverlayVisible: false,   // State to control overlay visibility
			callbackLink: null,        // Store the link the user clicked

		};
	},
	mounted() {
		// Bind the event listener on component mount
		this.bindLinkClickChecks();
	},
	beforeUnmount() {
		// Clean up the event listener when the component is destroyed
		document.body.removeEventListener('click', this.handleLinkClick);
	},
	methods: {
		bindLinkClickChecks() {
			console.log('Binding link click checks...');
			// Bind event listener to detect link clicks
			document.body.addEventListener('click', this.handleLinkClick);
		},
		handleLinkClick(e) {

			const target = e.target.closest('a:not(.pd-allow-pageleave, .pd-prevent-pageleave-overlay)');
			// eslint-disable-next-line no-undef
			// console.log('Link clicked:' , target, 'IS_PAGE_LEAVE_ACTIVE:', IS_PAGE_LEAVE_ACTIVE);
			if (target) {
				// If page leave is active, show the overlay and prevent the link navigation
				// eslint-disable-next-line no-undef
				if (IS_PAGE_LEAVE_ACTIVE) {
					e.preventDefault();
					this.callbackLink = target;
					this.showOverlay();
				}
			}
		},
		showOverlay() {
			this.isOverlayVisible = true;
		},
		hideOverlay() {
			this.isOverlayVisible = false;
		},
		confirmLeave() {
			// Perform cleanup operations (e.g., removing local storage)
			this.cleanUpLocalStorage();

			// Deactivate page leave blocking
			// eslint-disable-next-line no-undef
			IS_PAGE_LEAVE_ACTIVE = false;

			// Proceed to the original link
			if (this.callbackLink && this.callbackLink.href) {
				window.location.href = this.callbackLink.href;
			}

			this.hideOverlay();
		},
		cleanUpLocalStorage() {
			// Simulate removing local storage data
			// Replace this with actual logic if needed
			console.log('Simulating local storage cleanup...');

			// eslint-disable-next-line no-undef
			const searchId = global_js_vars.search_id;
			LOCAL_STORAGE_BY_SEARCH_MANAGER.delete(searchId, LOCAL_STORAGE_BY_SEARCH_FIELD_NAMES.local_augmentation_data);
			LOCAL_STORAGE_BY_SEARCH_MANAGER.delete(searchId, LOCAL_STORAGE_BY_SEARCH_FIELD_NAMES.active_assessment_result_index);
		}
	}
};
</script>

<style>
/* Add your styles for the overlay and buttons */
.pd-pageleave-overlay {
	/* Your CSS styles here */
}
</style>
